import { AxiosResponse } from 'axios';
import { getConfig } from '@ax/data-services-config';
import { UserSignup, UserMarketo } from '@/models/user';
import { showStandardHttpErrorNotification } from '@/utils/util';
import httpClient from './http-client';

const USER_ENDPOINT = '/users';
const MARKETO_SIGNUP_ENDPOINT = '/api/v1/signup/mkto-post-form-api';

export function userSignup(
  payload: UserSignup,
): Promise<void | AxiosResponse<void>> {
  return httpClient.post(USER_ENDPOINT, payload).catch((error) => {
    if (error) {
      showStandardHttpErrorNotification(error);
      throw error;
    }
  });
}

export function userMarketingCapture(payload: UserMarketo): Promise<void> {
  return getConfig().then((config) =>
    httpClient({
      method: 'POST',
      baseURL: config.marketing.url,
      url: MARKETO_SIGNUP_ENDPOINT,
      data: payload,
    }).then(({ data }) => data),
  );
}
