import { User } from '@ax/data-services-user/models/user';

// For user accounts tab
export interface UserWithTFA extends User {
  tfa_status: string;

  tfa_type?: string;
}

export class SamlResponse {
  enabled?: boolean;

  redirect?: string;

  constructor(init: SamlResponse) {
    Object.assign(this, init);
  }
}

export class UserSignup {
  firstname? = '';

  lastname? = '';

  email? = '';

  password? = '';

  // [NEW] FEATURE FLAG: ACCOUNT MANAGEMENT
  account_name? = '';

  // [LEGACY] FEATURE FLAG: ACCOUNT MANAGEMENT
  org_name? = '';

  campaign?: string;

  constructor(init: Partial<UserSignup>) {
    Object.assign(this, init);
  }
}

export class UserMarketo {
  email = '';

  endpoints: number | null = null;

  firstName = '';

  formid = '1018';

  lastName = '';

  request_url = '/signup';

  gtrue__c = String(true);

  constructor(init: Partial<UserMarketo>) {
    Object.assign(this, init);
  }
}
